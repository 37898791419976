import Vue from "vue";
import * as types from "mobile/store/types";
import * as globalTypes from "globals/store/types";
import DataAPI from "globals/services/DataAPI";
import merge from "lodash-es/merge";
import PunchAPI from "globals/services/PunchAPI";
import ChatAPI from "globals/services/ChatAPI";
import { isEmpty, titleCase } from "globals/utils/utils";
import getLocation from "globals/utils/geolocation";
import moment from "moment";
import cloneDeep from "lodash-es/cloneDeep";
import EventBus, { EVENTS } from "globals/utils/eventbus";
import { STRINGS, THREAD_NAME_TYPES } from "globals/utils/constant";
import { getDataStore } from "globals/store/local";
// Static

let newMessageTimeoutId = null;

// initial state, with craft backend or empty
const initialState = {
  punchsMadeOffline: [],
  lastFetchDate: "",

  //quiz
  lastAnsweredQuizDate: "",
  showQuiz: false,

  sitePath: "/",

  //last register location
  location: {
    lat: "",
    lng: "",
    accuracy: ""
  },

  departments: [],
  departmentGroups: [],
  punchs: [],
  lastPunch: null,
  branchs: [],
  companies: [],
  projects: [],
  activities: [],
  operations: [],
  employees: [],
  parameters: {},
  lastParamsUpdate: {},

  messagesByIds: {},
  messagesMadeOffline: [],
  messagesByThreadId: {},
  threads: [],

  version: "1.0.11"
};

// getters, make function easy to access by vue
const getters = {
  getRecipientsForThread: (state) => (thread) => {
    return thread.Recipients.split(",").reduce((acc, id) => {
      let recipient = state.employees.find((e) => e.idEmploye === id);
      if (!recipient) return acc;
      acc.push(titleCase(recipient.nomEmploye));
      return acc;
    }, []);
  },
  getThreadName: (state, getters) => (thread) => {
    if (!thread) return null;
    if (thread.DiscussionName !== "DEFAULT" && thread.DiscussionName !== "NEW THREAD") {
      return {
        name: thread.DiscussionName,
        type: THREAD_NAME_TYPES.DISCUSSION
      };
    }

    if (thread.IdDepartement !== "0") {
      let department = state.departments.find((d) => d.ID.toString() === thread.IdDepartement.toString());
      if (department) {
        return {
          name: department.Name,
          type: THREAD_NAME_TYPES.DEPARTMENT
        };
      }
    }

    if (thread.IdEtablissement !== "0") {
      let branch = state.branchs.find((b) => b.ID.toString() === thread.IdEtablissement.toString());
      if (branch) {
        return {
          name: branch.Name,
          type: THREAD_NAME_TYPES.BRANCH
        };
      }
    }

    if (thread.IdProjet !== "0") {
      let project = state.projects.find((p) => p.ID.toString() === thread.IdProjet.toString());
      if (project) {
        return {
          name: project.Name,
          type: THREAD_NAME_TYPES.PROJECT
        };
      }
    }

    if (thread.Recipients.length > 0) {
      let recipientsName = getters.getRecipientsForThread(thread);
      return {
        name: recipientsName,
        type: THREAD_NAME_TYPES.RECIPIENTS
      };
    }

    return {
      name: thread.DiscussionName,
      type: THREAD_NAME_TYPES.DISCUSSION
    };
  }
};

// actions
const actions = {
  [globalTypes.GET_SITE_PATH](store) {
    return DataAPI.getSitePath().then((url) => {
      store.commit(globalTypes.GET_SITE_PATH, url);
    });
  },
  [types.SEND_QUIZ](store, quizData) {
    return DataAPI.sendQuiz(quizData)
      .then((res) => {
        store.commit(types.SEND_QUIZ, quizData);
      })
      .catch((err) => {
        store.commit(types.SEND_QUIZ, quizData);
      });
  },
  [types.TOGGLE_QUIZ](store, payload) {
    store.commit(types.TOGGLE_QUIZ, payload);
  },
  [globalTypes.ADD_TO_OFFLINE_PUNCH](store, punchs) {
    store.commit(globalTypes.ADD_TO_OFFLINE_PUNCH, punchs);
  },
  [globalTypes.MAKE_ALL_OFFLINE_PUNCHS](store) {
    let punchs = cloneDeep(store.state.punchsMadeOffline);
    if (punchs.length === 0) return; //dont run if no data

    EventBus.$emit(EVENTS.APP_LOADER, true);

    punchs.sort((a, b) => {
      if (typeof a.CheckTime === "undefined") return 0;
      if (typeof b.CheckTime === "undefined") return 0;

      let aMoment = moment(a.CheckTime);
      let bMoment = moment(b.CheckTime);
      return aMoment.isBefore(bMoment) ? -1 : 1;
    });

    //reset to empty array
    store.commit(globalTypes.MAKE_ALL_OFFLINE_PUNCHS);

    return PunchAPI.punch(punchs)
      .then((punch) => {
        punchs.forEach((punchData) => {
          store.commit(globalTypes.PUNCH, punchData);
          store.commit(types.SAVE_LAST_PUNCH, punchData);
        });

        //reload data so we can new online data
        return store.dispatch(types.LOAD_USER_DATA, store.getters.userNIP);
      })
      .then(() => {
        //user data was loaded
        EventBus.$emit(EVENTS.APP_LOADER, false);
      })
      .catch((err) => {
        EventBus.$emit(EVENTS.APP_LOADER, false);
        if (err && err.Message) {
          store.commit(types.ERROR_MESSAGE, err.Message);
        }
      });
  },
  [types.LOAD_LOCATION](store) {
    return getLocation()
      .then((data) => {
        store.commit(types.LOAD_LOCATION, data);
        return data;
      })
      .catch((err) => {
        //reset to 0
        store.commit(types.LOAD_LOCATION, {
          latitude: "0",
          longitude: "0",
          accuracy: "-1"
        });
        return err;
      });
  },
  [types.LOAD_USER_DATA](store, nip) {
    return new Promise((resolve, reject) => {
      store
        .dispatch(types.GET_USER_PARAMETERS, nip)
        .then((user) => {
          if (!user) {
            store.commit(types.ERROR_MESSAGE, STRINGS(store.rootState.auth.lang).ERROR_VERIFY);
            reject();
            return null; //stop calling all the parameters
          }

          store.commit(types.LAST_FETCH_DATE); //save the date we fetch those data
          store
            .dispatch(types.GET_PARAMETERS, user.UserId)
            .then((allData) => {
              resolve(allData);
            })
            .catch(() => {
              reject();
            });
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [types.GET_PROJECTS](store, userId) {
    return DataAPI.getProjects(userId).then((projects) => {
      store.commit(types.GET_PROJECTS, projects);
    });
  },
  [types.GET_COMPANIES](store) {
    return DataAPI.getCompanies().then((companies) => {
      store.commit(types.GET_COMPANIES, companies);
    });
  },
  [types.GET_PARAMETERS_LAST_UPDATE](store, userId) {
    return DataAPI.getParametersLastUpdate(userId).then((lastUpdateData) => {
      store.commit(types.GET_PARAMETERS_LAST_UPDATE, lastUpdateData);
      return lastUpdateData;
    });
  },
  [types.GET_DEPARTMENTS](store) {
    return DataAPI.getDepartments().then((departments) => {
      store.commit(types.GET_DEPARTMENTS, departments);
    });
  },
  [types.GET_DEPARTMENTS_BY_GROUP](store, groupId) {
    return DataAPI.getDepartmentsByGroup(groupId).then((departments) => {
      store.commit(types.GET_DEPARTMENTS_BY_GROUP, departments);
    });
  },
  [types.GET_DEPARTMENT_GROUPS](store) {
    return DataAPI.getDepartmentGroups().then((departmentGroups) => {
      store.commit(types.GET_DEPARTMENT_GROUPS, departmentGroups);
    });
  },
  [types.GET_DEFAULT_DEPARTMENTS](store, userId) {
    return DataAPI.getDefaultDepartments(userId).then((departments) => {
      store.commit(types.GET_DEPARTMENTS, departments);
    });
  },
  [types.GET_ACTIVITIES](store, userId) {
    return DataAPI.getActivities().then((activities) => {
      if (!activities) return [];
      store.commit(types.GET_ACTIVITIES, activities);
      return activities;
    });
  },
  [types.GET_BRANCHS](store, userId) {
    return DataAPI.getBranchs().then((branchs) => {
      if (!branchs) return [];
      store.commit(types.GET_BRANCHS, branchs);
      return branchs;
    });
  },
  [types.GET_OPERATIONS](store) {
    return DataAPI.getOperations().then((operations) => {
      if (!operations) return [];
      store.commit(types.GET_OPERATIONS, operations);
      return operations;
    });
  },
  [types.GET_NOTIFICATIONS](store, userId) {
    return DataAPI.getNotifications(userId).then((notifications) => {
      if (!notifications) return [];
      store.commit(types.GET_NOTIFICATIONS, notifications);
      return notifications;
    });
  },
  [types.GET_EMPLOYEES](store, payload) {
    return DataAPI.getEmployees().then((employees) => {
      if (!employees) return [];
      store.commit(types.GET_EMPLOYEES, employees);
      return employees;
    });
  },
  [types.GET_UNREAD_MESSAGES](store, senderId) {
    return ChatAPI.getAllUnreadMessages(senderId).then((messages) => {
      if (messages.length === 0) {
        EventBus.$emit(EVENTS.NEW_MESSAGE, false);
        return;
      }

      //check new message before commiting
      const hasRealNewMessages = messages.some((message) => {
        return !store.state.messagesByIds[message.IdAuto];
      });

      //commit so it's updated
      store.commit(types.GET_MESSAGES, messages);

      if (!hasRealNewMessages) {
        //EventBus.$emit(EVENTS.NEW_MESSAGE, false);
        return;
      }

      //we have real messages
      //only play sound when really new message got fetched
      EventBus.$emit(EVENTS.NEW_MESSAGE, true);

      try {
        //vibrate + sound
        window.navigator.vibrate(200);
      } catch (e) {}
      try {
        let _soundNode = document.querySelector("#notification-sound");
        if (!_soundNode) return;

        const promise = _soundNode.play();
        if (promise !== undefined) {
          promise
            .then(() => {
              // Autoplay started
            })
            .catch((err) => {
              // eslint-disable-next-line no-console
              console.error(err);
            });
        }
        //make it sound
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
      }
    });
  },
  [types.GET_THREADS](store, senderId) {
    return ChatAPI.getThreads(senderId).then((data) => {
      if (!data) return [];
      store.commit(types.GET_THREADS, data.Discussions);
      return data.Discussions;
    });
  },
  [types.GET_MESSAGES](store, { lastMessageId = 0, senderId }) {
    return ChatAPI.getMessages(senderId, lastMessageId).then((messages) => {
      if (!messages) return [];
      store.commit(types.GET_MESSAGES, messages);
      return messages;
    });
  },
  [types.GET_THREAD](store, { userId, threadId }) {
    return ChatAPI.getThread(userId, threadId).then((data) => {
      if (!data) return data;
      store.commit(types.GET_THREAD, data.thread);
      store.commit(types.GET_MESSAGES, data.messages);
    });
  },
  [types.ADD_TO_OFFLINE_MESSAGE](store, message) {
    store.commit(types.ADD_TO_OFFLINE_MESSAGE, message);
  },
  [types.MAKE_ALL_OFFLINE_MESSAGES](store) {
    let messages = cloneDeep(store.state.messagesMadeOffline);

    //reset to empty array
    store.commit(types.MAKE_ALL_OFFLINE_MESSAGES);

    let promises = [];
    for (let message of messages) {
      promises.push(store.dispatch(types.CREATE_MESSAGE, message));
    }

    return Promise.all(promises).catch((err) => {
      if (err && err.Message) {
        store.commit(types.ERROR_MESSAGE, err.Message);
      }
    });
  },
  [types.CREATE_MESSAGE](store, { senderId, message, threadId, lastMessageId }) {
    return ChatAPI.createMessage(lastMessageId, senderId, message).then((messages) => {
      if (messages) {
        store.commit(types.GET_MESSAGES, messages);
      }
      return message;
    });
  },
  [types.MESSAGE_WAS_VIEWED](store, { userId, messageId }) {
    return ChatAPI.messageWasViewed(messageId, userId).then((thread) => {});
  },
  [types.CREATE_THREAD](store, payload) {
    return ChatAPI.createThread(payload).then((thread) => {
      store.commit(types.GET_THREAD, thread);
      return thread;
    });
  },
  [globalTypes.PUNCH](store, punches) {
    if (!store.rootState.general.isOnline) {
      punches.forEach((punchData) => {
        store.commit(globalTypes.PUNCH, punchData);
        store.commit(types.SAVE_LAST_PUNCH, punchData);
      });
      return null;
    }

    return PunchAPI.punch(punches)
      .then((punch) => {
        punches.forEach((punchData) => {
          store.commit(globalTypes.PUNCH, punchData);
          store.commit(types.SAVE_LAST_PUNCH, punchData);
        });
        return punch;
      })
      .catch((err) => {
        if (err && err.Message) {
          store.commit(types.ERROR_MESSAGE, err.Message);
        }
      });
  },
  //todo convert this like ipad since its not for userId....
  [types.GET_PARAMETERS](store, userId) {
    return new Promise((resolve, reject) => {
      DataAPI.getParams()
        .then((data) => {
          //start with empty array
          let promises = [
            //global
            store.dispatch(types.GET_NOTIFICATIONS, userId),
            store.dispatch(globalTypes.GET_SITE_PATH)
          ];

          //fetch depends on params values
          if (data.DepartmentsUsed) {
            promises.push(store.dispatch(types.GET_DEPARTMENTS));
          }
          if (data.useDepartmentGroups) {
            promises.push(store.dispatch(types.GET_DEPARTMENT_GROUPS));
          }
          if (data.ActivitiesUsed) {
            promises.push(store.dispatch(types.GET_ACTIVITIES, userId));
          }
          if (data.BranchUsed) {
            promises.push(store.dispatch(types.GET_BRANCHS, userId));
          }
          if (data.OperationsUsed) {
            promises.push(store.dispatch(types.GET_OPERATIONS));
          }
          if (data.ProjectUsed) {
            promises.push(store.dispatch(types.GET_PROJECTS, userId));
          } else {
            promises.push(store.dispatch(types.GET_DEFAULT_DEPARTMENTS));
          }

          if (data.mobilePunchUseCompanies) {
            promises.push(store.dispatch(types.GET_COMPANIES));
          }

          Promise.all(promises)
            .then((allData) => {
              resolve(allData);
            })
            .catch(() => {
              reject();
            });

          store.commit(types.GET_PARAMETERS, data);
          return data;
        })
        .catch((err) => {
          // console.log(err)
        });
    });
  }
};

// mutations
const mutations = {
  [globalTypes.GET_SITE_PATH](state, url) {
    state.sitePath = url;
  },
  [types.LOGIN](state) {
    //use window because that fix the infinte loop
    window.vueStore.commit(types.SET_SHOW_QUIZ, true);
  },
  [globalTypes.ADD_TO_OFFLINE_PUNCH](state, punchs) {
    punchs.forEach((punch) => {
      state.punchsMadeOffline.push(punch);
    });
  },
  [types.ADD_TO_OFFLINE_MESSAGE](state, message) {
    state.messagesMadeOffline.push(message);
  },
  [types.LAST_FETCH_DATE](state) {
    state.lastFetchDate = moment().format();
  },
  [types.SEND_QUIZ](state, quizData) {
    //update to now
    state.lastAnsweredQuizDate = moment().format();
  },
  [types.TOGGLE_QUIZ](state, show) {
    state.showQuiz = show;
  },
  [globalTypes.MAKE_ALL_OFFLINE_PUNCHS](state) {
    state.punchsMadeOffline = [];
  },
  [types.MAKE_ALL_OFFLINE_MESSAGES](state) {
    state.messagesMadeOffline = [];
  },
  [types.LOAD_LOCATION](state, data) {
    if (isEmpty(data)) return;
    state.location = {
      lat: data.latitude,
      lng: data.longitude,
      accuracy: data.accuracy
    };
  },
  [types.GET_BRANCHS](state, branchs) {
    state.branchs = branchs;
  },
  [types.GET_ACTIVITIES](state, activities) {
    state.activities = activities;
  },
  [types.GET_PROJECTS](state, projects) {
    state.projects = projects;
  },
  [types.GET_COMPANIES](state, companies) {
    state.companies = companies;
  },
  [types.GET_PARAMETERS](state, params) {
    state.parameters = params;
  },
  [types.GET_PARAMETERS_LAST_UPDATE](state, lastUpdateData) {
    state.lastParamsUpdate = lastUpdateData;
  },
  [types.GET_OPERATIONS](state, operations) {
    state.operations = operations;
  },
  [types.GET_DEPARTMENT_GROUPS](state, departmentGroups) {
    departmentGroups.forEach((d) => {
      let alreadyThere = state.departmentGroups.find((d_) => d_.ID === d.ID);
      if (!alreadyThere) {
        state.departmentGroups.push(d);
      }
    });
  },
  [types.GET_DEPARTMENTS_BY_GROUP](state, departments) {
    departments.forEach((d) => {
      let alreadyThere = state.departments.find((d_) => d_.ID === d.ID);
      if (!alreadyThere) {
        state.departments.push(d);
      }
    });
  },
  [types.GET_DEPARTMENTS](state, departments) {
    departments.forEach((d) => {
      let alreadyThere = state.departments.find((d_) => d_.ID === d.ID);
      if (!alreadyThere) {
        state.departments.push(d);
      }
    });
  },
  [globalTypes.PUNCH](state, punch) {
    state.punchs.push(punch);
  },
  [types.SAVE_LAST_PUNCH](state, punch) {
    state.lastPunch = punch;
  },
  [types.CREATE_MESSAGE](state, message) {
    let messagesByThreadId = {
      ...state.messagesByThreadId
    };
    if (typeof messagesByThreadId[message.DiscussionId] === "undefined") {
      messagesByThreadId[message.DiscussionId] = [];
    }
    messagesByThreadId[message.DiscussionId].push(message);

    state.messagesByThreadId = messagesByThreadId;
    Vue.set(state, "messagesByThreadId", messagesByThreadId);
  },
  [types.GET_THREAD](state, thread) {
    let idx = state.threads.findIndex((t) => t.IdAuto === thread.IdAuto);
    if (idx === -1) {
      state.threads.push(thread); //add it
    } else {
      state.threads[idx] = thread;
    }
  },
  [types.GET_MESSAGES](state, messages) {
    let messagesByThreadId = {
      ...state.messagesByThreadId
    };

    messages.forEach((m) => {
      if (typeof messagesByThreadId[m.DiscussionId] === "undefined") {
        messagesByThreadId[m.DiscussionId] = [];
      }
      let idx = messagesByThreadId[m.DiscussionId].findIndex((_m) => _m.IdAuto === m.IdAuto);
      if (idx === -1) {
        messagesByThreadId[m.DiscussionId].push(m);
      } else {
        messagesByThreadId[m.DiscussionId][idx] = m;
      }
      state.messagesByIds[m.IdAuto] = m;
    });

    state.messagesByThreadId = messagesByThreadId;
    Vue.set(state, "messagesByThreadId", messagesByThreadId);
  },
  [types.GET_NOTIFICATIONS](state, notifications) {},
  [types.GET_EMPLOYEES](state, employees) {
    state.employees = employees;
  },
  [types.GET_THREADS](state, threads) {
    let newThreads = [...state.threads];
    threads.forEach((t) => {
      let idx = newThreads.findIndex((t_) => t_.IdAuto === t.IdAuto);
      if (idx === -1) {
        newThreads.push(t);
      } else {
        newThreads[idx] = t;
      }
    });

    //sort by date

    newThreads.sort((a, b) => {
      return moment(a.LastMessageOn).isAfter(b.LastMessageOn) ? -1 : 1;
    });

    state.threads = newThreads;
    Vue.set(state, "threads", newThreads);
  },
  [types.RESTORE_MUTATION](state, payload) {
    if (!payload) {
      //we need to still ask for the quiz then
      state.showQuiz = true;
      return;
    }
    if (!payload.data) {
      //we need to still ask for the quiz then
      state.showQuiz = true;
      return;
    }

    let excludes = ["showQuiz", "messagesByIds"];

    //set state
    //only if same version
    if (payload.data.version && payload.data.version === initialState.version) {
      Object.keys(payload.data).forEach((key) => {
        if (!isEmpty(payload.data[key])) {
          if (!excludes.includes(key)) {
            state[key] = JSON.parse(JSON.stringify(payload.data[key]));
          }
        }
      });
    }

    //use window because that fix the infinte loop
    window.vueStore.commit(types.SET_SHOW_QUIZ, true);
  },
  [types.SET_SHOW_QUIZ](state, init) {
    if (init) {
      //need to answer every day
      if (isEmpty(state.lastAnsweredQuizDate)) {
        state.showQuiz = true;
      } else {
        let lastTimeAnswer = moment(state.lastAnsweredQuizDate);
        let isSameDay = lastTimeAnswer.isSame(moment(), "day");
        //we need to answer the quiz every day, so check if its not the same day
        //id not we show the quiz
        //also if the quiz date is empty, we need to answer it(probably the first time, or complete reset of data)
        if (!isSameDay) {
          state.showQuiz = true;
        }
      }
    } else {
      //reinit
      state.showQuiz = true;
      state.lastAnsweredQuizDate = "";
    }
  },
  [types.LOGOUT](state) {
    //keep those since we will do them when reconnected
    let excludes = ["punchsMadeOffline", "messagesMadeOffline", "location"];

    Object.keys(state).forEach((key) => {
      if (!excludes.includes(key)) {
        try {
          state[key] = JSON.parse(JSON.stringify(initialState[key]));
        } catch (e) {}
      }
    });

    //use window because that fix the infinte loop
    window.vueStore.commit(types.SET_SHOW_QUIZ, false);

    //delete vuex persist setup
    getDataStore().clear();
  }
};

export default {
  namespaced: false,
  state: merge({}, initialState), //make sure its not the same object
  getters,
  actions,
  mutations
};
